import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnInit, QueryList, Renderer2, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Produto, additionalProduct, categoriesAdditional } from 'src/app/Interfaces/produtos';
import { Pedido, PedidoClass, additional, categoryAdd} from 'src/app/Interfaces/pedido';
import { ToastrService } from 'ngx-toastr';
import { CartService } from 'src/app/shared/services/Cart/cart.service';
import { Company, ConnectionService } from 'src/app/shared/services/Connection/connection.service';
import { DOCUMENT } from '@angular/common';
import { ApiService } from 'src/app/shared/services/API/api.service';
import { ScrollService } from 'src/app/shared/services/Scroll/scroll.service';
import { typeInterface } from 'src/app/Interfaces/company';
import { PixelService } from 'src/app/shared/services/pixel.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})

export class ProductDetailComponent implements OnInit, AfterViewInit{

  //#region Variaveis de elementos HTML
    @ViewChildren('sectionsCategory') sectionsCategory!: QueryList<ElementRef>;
  //#endregion

  teste: any;

  //#region Variaveis de Estilo
  alterationBg = false;
  showSideBar = true;
  toggleTopNavegation = true;
  alterationTopNavegation = false;
  showbutton = true;
  isZoom = false;
  desabilitButton = false;
  limitFlavors = false;
  limitEdges = false;
  readOnlyState: boolean = false;
  collapseStates: boolean[] = [];
  //#endregion

  //#region Variaveis Locais

    //Variaveis Privadas
    private typeParam!: typeInterface;
    private nameCompany: string = '';

    //Variaveis Booleanas
    requestSucess = false;
    permissionRequest = true;

    //Variaveis string
    textObservation: string = '';
    filter: string = '';

    //Variaveis Numericas
    scrollTopPosition: number = 0;
    statusCompany: number = 0;
    countText: number = 0;
    totalValue: number = 0;
    valorBase = 0;
    id!: number;
    counter: number = 1;

    //Variaveis de lista
    sectionPositions: number[] = [];
    additionalOrder: categoryAdd[] = [];

    //Variaveis de Objetos
    temporaryOrde!:Pedido;
    produto!: Produto;
    productOrder!: Produto;
    pedido!: PedidoClass;
    company!: Company;
    baseFlavorsValue = 0;

    subCategoryEmpty: number[] = [];
  //#endregion

  //#region Inicializadores

    constructor(
      private toastr: ToastrService,
      private route: ActivatedRoute,
      private router: Router,
      private cartService: CartService,
      private changeDetectorRef: ChangeDetectorRef,
      private connection: ConnectionService,
      private scrollService: ScrollService,
      private apiService: ApiService,
      private renderer: Renderer2,
      private elementRef: ElementRef,
      private pixelService: PixelService,
      @Inject(DOCUMENT) private document: Document
      ){
        this.connection.nameCompany$.subscribe((newNameCompany) => {
          this.nameCompany = newNameCompany;
        })
        this.connection.statusCompany$.subscribe((newStatus) =>{
          this.statusCompany = newStatus
        })
        this.connection.typeParam$.subscribe((newTypeParam) => {
          this.typeParam = newTypeParam;
          if(this.company){
            this.validationReadOnly();
          }
        })
        this.connection.company$.subscribe((newCompany) =>{
          this.company = newCompany
          if(this.typeParam){
            this.validationReadOnly();
          }
        })
        this.connection.permissionRequestOrder$.subscribe((newPermission) =>{
          this.permissionRequest = newPermission
        })
    }

    ngOnInit(){
      //Resgata o parametro id
      this.route.params.subscribe(params => {
        this.id = params['id_product'];
      })

      //Resgata as informações do produto
      this.getProduct();

      this.scrollService.scrollingSubject.next(true);

      //Altera o background
      this.alterationBG();

      //Desabilita o o refresh ao arrastar para baixo
      this.disablePullToRefresh();
    }

    ngAfterViewInit(){
      //Inclui ou remove a sidebar
      this.toggleSidebar();

      //Altera o menu de navegação superior
      this.toggleNavegationTop();

      //Calcula a posição das categorias de adicionais
      setTimeout(() => {
        this.calculateSectionPositions()
      }, 1000);

      //Iniciar o component sempre no topo da pagina

    }

    ngOnDestroy() {}
  //#endregion

  //#region Funções de estilo (Style)

    //Inclui ou remove a sidebar da tela
    toggleSidebar(){
      if(window.innerWidth < 1169){
        this.showSideBar = false
        this.showbutton = false;
        this.changeDetectorRef.detectChanges();
      }
      else {
        this.showbutton = true;
        this.showSideBar = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    //Altera o menu de navegação superior
    toggleNavegationTop(){
      if(window.innerWidth < 700)
      {
        this.toggleTopNavegation = false
        this.changeDetectorRef.detectChanges();
      }
      else if(window.innerWidth > 700)
      {
        this.toggleTopNavegation = true;
        this.changeDetectorRef.detectChanges();
      }
    }

    //Função responsavel por travar de o scroll para que a tela não seja recarregada no menu
    disablePullToRefresh() {
      const element = this.elementRef.nativeElement;
      let isAtTop = true;

      // Adiciona um ouvinte para o evento de scroll
      this.renderer.listen(window, 'scroll', () => {
        // Verifica se a página está no topo
        isAtTop = window.scrollY === 0;
      });

      this.renderer.listen(element, 'touchstart', (event) => {
        // Registra a posição inicial do toque
        const startY = event.touches[0].clientY;

        // Adiciona um ouvinte para o evento de movimento de toque
        const touchMoveListener = this.renderer.listen(element, 'touchmove', (moveEvent) => {
          // Calcula a diferença entre a posição inicial e a posição atual
          const deltaY = moveEvent.touches[0].clientY - startY;

          // Se a diferença for positiva (movimento para baixo) e a página estiver no topo, previne o comportamento padrão
          if (deltaY > 0 && isAtTop) {
            moveEvent.preventDefault();
          }
        });

        // Adiciona um ouvinte para o evento de término de toque
        const touchEndListener = this.renderer.listen(element, 'touchend', () => {
          // Remove os ouvintes após o término do toque
          touchMoveListener();
          touchEndListener();
        });
      });
    }

    //inclui a barra superior do menu de navegação
    includeDivBar(){
      this.scrollTopPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      if(window.innerWidth <= 846)
      {
        if(this.scrollTopPosition >= 380)
        {
          this.alterationTopNavegation = true
        }
        else{
          this.alterationTopNavegation = false
        }
      }
      else{}
    }

    //Abre a Imagem expandida
    zoomImageFirst(){
      this.isZoom = true;
    }

    //Fecha a imagem expandida
    notZoomImageLast(){
      this.isZoom = false;
    }

    //Altera o background
    alterationBG(){
      if(window.innerWidth <= 700)
      {
        this.alterationBg = false
      }
      else{
        this.alterationBg = true
      }
      this.changeDetectorRef.detectChanges();
    }

    //remove a cor do botão de subtração se o counter <= 0
    removeOneInCounter(){
      this.counter = this.counter - 1;

      if(this.produto.pizza != 1)
      {
        this.totalValue = this.valorBase * this.counter
      }
      else{
        this.totalValue = this.totalValue * this.counter

        if(this.company.company.pizzaDivision  == 0)
        {
          this.logicDivision();
        }
        //Maior Valor
        else if(this.company.company.pizzaDivision  == 1)
        {
          this.logicMaxValue();
        }
        //Média Ponderada
        else if(this.company.company.pizzaDivision  == 2 || this.company.company.pizzaDivision == 3)
        {
          this.logicDivisionCeil();
        }
      }

    }

    //adiciona cor ao botão de subtração se o counter > 0
    addOneInCounter(){
      this.counter = this.counter + 1;
      if(this.produto.pizza != 1)
      {
        this.totalValue = this.valorBase * this.counter
        
      }
      else{
        this.totalValue = this.totalValue * this.counter
        if(this.company.company.pizzaDivision  == 0)
        {
          this.logicDivision();
        }
        //Maior Valor
        else if(this.company.company.pizzaDivision  == 1)
        {
          this.logicMaxValue();
        }
        //Média Ponderada
        else if(this.company.company.pizzaDivision  == 2 || this.company.company.pizzaDivision == 3)
        {
          this.logicDivisionCeil();
        }
      }

    }

    @HostListener('window:resize', [])
    onResize(){
      this.alterationBG();

      this.toggleSidebar();

      this.toggleNavegationTop();

    }

    @HostListener('window:scroll', [])
    onScroll() {
      this.includeDivBar()
    }

    //captura toda tecla clicado no text area e faz a contagem
    capturaTexArea(event: Event){
      const inputElement = event?.target as HTMLInputElement;

      if (inputElement.value.length <= 48) {
        this.textObservation = inputElement.value;
        this.countText = this.textObservation.length;
      } else {
        inputElement.value = this.textObservation;
      }
    }

    //Checa a categoria e bloqueia se atingir numero maximo
    checkCategory(categorieSelected: any, i: number){

      const element = document.querySelectorAll('.box-collapse')

      // console.log(element)

      const category = this.additionalOrder.find(
        category => categorieSelected.id_category === category.id_category
      )

      let counter = 0
      if(category){
        category.additional.forEach(element =>{
          counter += element.quantitySub
        })
      }

      if(category){
        if(category?.division != 0)
        {
          if(counter == categorieSelected.division)
          {
            element[i].classList.add('disabled')

          }
          else{
            element[i].classList.remove('disabled');
          }
        }
      }
      else{
        element[i].classList.remove('disabled');
      }


    }

    //Calcula as posições das categorias dos adicionais
    calculateSectionPositions() {
      this.sectionPositions = []
      this.sectionsCategory.forEach(section => {
        if (section && section.nativeElement) {

          this.sectionPositions.push(section.nativeElement.offsetTop);
        }
      });
    }

    //Da o scroll até a categoria de adicionais
    scrollToCategory(categorieSelected: any, i: number){
      this.calculateSectionPositions()
      const category = this.additionalOrder.find(
        category => categorieSelected.id_category === category.id_category
      )

      let categoryLength = 0;

      category?.additional.forEach(additional =>{
        categoryLength += additional.quantitySub
      })
      if(category?.division == categoryLength){
        if(this.limitFlavors)
        {
          for (let index = 0; index < this.produto.categories.length; index++) {
            const category = this.produto.categories[index];
            if(index >= i){
              if (category.flavorCategory === 0) {
                window.scrollTo({ top: this.sectionPositions[index], behavior: 'smooth' });

                break;
              }
              else{
                if(index == this.produto.categories.length)
                {
                  window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                }
              }
            }
          }
        }
        else{
          window.scrollTo({top: this.sectionPositions[Number(i + 1)], behavior: 'smooth'});
        }
      }
      else{
        if(category?.flavorCategory != 0){
          if(this.limitFlavors)
          {
            
            for (let index = 0; index < this.produto.categories.length; index++) {
              const category = this.produto.categories[index];
              if(index >= i){
                if (category.flavorCategory === 0) {
                  window.scrollTo({ top: this.sectionPositions[index], behavior: 'smooth' });
                  break;
                }
                else{
                  if(index == this.produto.categories.length)
                  {
                    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                  }
                }
              }
            }
          }
        }

      }
    }

    //Verifica se todas as categorias requeridas estão selecionadas para poder avançar
    confimationCategories(){
      if(this.produto.categories){
        const categoriasRequired = this.produto.categories.filter(category => category.required === 1)
        const categoriasRequiredOrder = this.additionalOrder.filter(category => category.required === 1)

        if(categoriasRequired.length != categoriasRequiredOrder.length){
          this.desabilitButton = true;
          if(this.produto.pizza == 1)
          {
            let counterFlavors = 0;
            let counterEdge = 0;
            this.additionalOrder.forEach(categoria => {
              categoria.additional.forEach(adicional =>{
                if(categoria.flavorCategory == 1)
                {
                  counterFlavors = counterFlavors + adicional.quantitySub
                }
                else if(categoria.edgeCategory == 1){

                  counterEdge = counterEdge + adicional.quantitySub
                }
              })
            })
  
            if(counterFlavors < this.produto.maxDivision)
            {
              this.limitFlavors = false
            }
            else if(counterFlavors == this.produto.maxDivision)
            {
              this.limitFlavors = true;
            }

            if(this.produto.edgeDivision != 0){

              if(counterEdge < this.produto.edgeDivision)
              {
                this.limitEdges = false
              }
              else if(counterEdge == this.produto.edgeDivision)
              {
                this.limitEdges = true;
              }
            }
          }
        }
        else{
          if(this.produto.pizza == 1)
          {
            let counterFlavors = 0;
            let counterEdge = 0;
            this.additionalOrder.forEach(categoria => {
              categoria.additional.forEach(adicional =>{
                if(categoria.flavorCategory == 1)
                {
                  counterFlavors = counterFlavors + adicional.quantitySub
                }
                else if(categoria.edgeCategory == 1)
                {
                  counterEdge = counterEdge + adicional.quantitySub
                }
              })
            })
  
            if(counterFlavors < this.produto.maxDivision)
            {
              this.limitFlavors = false
              if(counterFlavors < 1)
              {
                this.desabilitButton = true;
              }
              else{
                this.desabilitButton = false;
              }
            }
            else if(counterFlavors == this.produto.maxDivision)
            {
              this.desabilitButton = false;
              this.limitFlavors = true;
  
            }


            if(this.produto.edgeDivision != 0){

              if(counterEdge < this.produto.edgeDivision)
              {
                this.limitEdges = false
              }
              else if(counterEdge == this.produto.edgeDivision)
              {
                this.limitEdges = true;
              }
            }
          }
          else{
            this.desabilitButton = false;
          }


        }
      }
    }

    //Abre e fecha as categorias dos adicionais
    toggleCollapse(index: number):void{
      this.collapseStates[index] = !this.collapseStates[index];
    }

    //desabilita o botão de adicionar de acordo com as condições
    desabilitButtons(){
      if(this.produto.categories){
        if(this.produto.categories.length == 0)
        {
          this.desabilitButton = false;
        }
        else{

          const categoriasRequired = this.produto.categories.filter(category => category.required === 1)

          if(categoriasRequired)
          {
            if(categoriasRequired.length != 0){
              this.desabilitButton = true;
            }
            else{
              if(this.produto.pizza == 1)
              {
                this.desabilitButton = true;
              }

            }
          }

        }

      }
      else{
        this.desabilitButton = false;
      }
    }

    validationReadOnly(){
      //-------------------------
      //TYPE INTERFACE
      // 0 = Delivery
      // 1 - Mesa
      // 2 - Comanda
      // 3 - Balcão
      //-----------------------
      if(this.company.configs.readOnly == 0){
        if(this.typeParam.type == 0){
          if(this.company.configs.deliveryReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
  
        }
        else if(this.typeParam.type == 1){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 2){
          if(this.company.configs.localReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
        else if(this.typeParam.type == 3){
          if(this.company.configs.counterReadOnly == 1){
            this.readOnlyState = true;
          }
          else{
            this.readOnlyState = false;
          }
        }
      }
      else{
        this.readOnlyState = true;
      }
    }


  //#endregion

  //#region Funções de lógica (Logic)

    //Puxa todas as caracteristicas do produto selecionado
    getProduct(){
        this.apiService.getProduto(this.id, this.typeParam.type).subscribe((data) =>{

          //Variavel produto recebe o valor do request
          this.produto = data.Product

          setTimeout(() => {
            this.requestSucess = true
            window.scrollTo({
              behavior: 'instant' as ScrollBehavior,
              top: 0,
              left: 0
            })
          }, 200);

          //Percorre o produto e adiciona zero a todos adicionais
          if(this.produto.categories)
          {
            this.produto.categories.forEach(category => {
              category.products.forEach(produto =>{
                produto.quantity = 0;
              })
            });
          }

          //Carrega as variaveis com o preço do produto
          this.totalValue = Number(this.produto.price)
          this.valorBase = Number(this.produto.price)

          //Extrai as categorias de produto e deixa as categorias de productOrder vazias
          const { categories, ...productWithoutCategories } = this.produto;

          this.productOrder = {
            ...productWithoutCategories,
            categories: [],
          };

          //Verifica as condições para desabilitar o botão de adicionar
          this.desabilitButtons();
        },
        (error) =>{
          this.requestSucess = false;
        }
        )

    }

    //Calcula o total do produto com adicionais
    calcTotalPedido(){
      this.valorBase = 0
      this.totalValue = 0;
      this.totalValue = this.produto.price

      this.additionalOrder.forEach(category => {
        category.additional.forEach(additionais => {
          this.totalValue = Number(this.totalValue) + Number(additionais.totalPriceSub)
          this.changeDetectorRef.detectChanges()
        });

      });
      this.valorBase = this.totalValue

      this.totalValue = this.totalValue * this.counter
    }

    //retorna a pagina anterior
    goback(): void{
      this.cartService.productEditionSubject.next(this.temporaryOrde)
      if(this.typeParam.type == 1 || this.typeParam.type == 2)
      {
        this.router.navigate([this.nameCompany, 'P']);
      }
      else if(this.typeParam.type == 3)
      {
        this.router.navigate([this.nameCompany, 'B']);
      }
      else{
        this.router.navigate([this.nameCompany]);
      }
    }

    //#region Lógica para adicionar e remover adicionais

    //remove um nos adicionais
    removeOneInCounterAdd(adicional: additionalProduct, categorieSelected: any, i: number){
      if (adicional.quantity > 0) {
        adicional.quantity--;
      }

      const indexCategoria = this.additionalOrder.findIndex(item => item.id_category === categorieSelected.id_category);

      if (indexCategoria !== -1) {
        const categoria = this.additionalOrder[indexCategoria];

        const indexAdicional = categoria.additional.findIndex(item => item.add_id_product == adicional.id_product);

        if (indexAdicional !== -1) {

          const additional = this.additionalOrder[indexCategoria].additional[indexAdicional];

          if(additional.quantitySub > 1)
          {
            this.additionalOrder[indexCategoria].additional[indexAdicional].quantitySub -= 1
            this.additionalOrder[indexCategoria].additional[indexAdicional].totalPriceSub -= this.additionalOrder[indexCategoria].additional[indexAdicional].unitPriceSub
          }
          else{
            this.additionalOrder[indexCategoria].additional.splice(indexAdicional, 1);
          }

        }

        if(categoria.additional.length == 0)
        {
          this.additionalOrder.splice(indexCategoria, 1)
        }

        if(this.produto.pizza == 1)
        {
          if(this.additionalOrder.length != 0)
          {
            //Média
            if(this.company.company.pizzaDivision == 0)
            {
              this.logicDivision();
            }
            //Maior
            else if(this.company.company.pizzaDivision  == 1)
            {
              this.logicMaxValue();
            }
            //Média Ponderada
            else if(this.company.company.pizzaDivision  == 2 || this.company.company.pizzaDivision == 3)
            {
              this.logicDivisionCeil();
            }
          }
          else{
            this.totalValue = 0;
          }
        }
        else{
          this.calcTotalPedido()
        }

        this.confimationCategories();

      }
      this.checkCategory(categorieSelected, i)
    }

    //Adiciona um nos adicionais
    addOneInCounterAdd(adicional: additionalProduct, categorieSelected: any, i: number){

      adicional.quantity++

      const item: additional = {
        add_id_product : adicional.id_product,
        nameSub: adicional.name,
        quantitySub: adicional.quantity,
        unitPriceSub: adicional.price,
        totalPriceSub: adicional.price * adicional.quantity,
        paymentAccept: adicional.paymentAccept,
        observationsSub: ''
      }

      const existingCategory = this.additionalOrder.find(category => categorieSelected.id_category === category.id_category)

      if(existingCategory){

        const existingItem = existingCategory.additional.find(subItem => item.add_id_product === subItem.add_id_product)

        if(existingItem)
        {
          existingItem.quantitySub = item.quantitySub
          existingItem.totalPriceSub = item.totalPriceSub
          existingItem.observationsSub = item.observationsSub
        }
        else{
          existingCategory.additional.push(item)
        }
      }
      else{

        const category: categoryAdd = {
          id_category: categorieSelected.id_category,
          categoryName: categorieSelected.categoryName,
          additional: [],
          division: categorieSelected.division,
          flavorCategory: categorieSelected.flavorCategory,
          edgeCategory: categorieSelected.edgeCategory,
          required: categorieSelected.required,
          categoryLength: categorieSelected.categoryLength
        }
        
        category.additional.push(item)
        this.additionalOrder.push(category)
      }

      if(this.produto.pizza == 1)
      {
        //Média
        if(this.company.company.pizzaDivision  == 0)
        {
          this.logicDivision();
        }
        //Maior Valor
        else if(this.company.company.pizzaDivision  == 1)
        {
          this.logicMaxValue();
        }
        //Média Ponderada
        else if(this.company.company.pizzaDivision == 2 || this.company.company.pizzaDivision == 3)
        {
          this.logicDivisionCeil();
        }

      }
      else{
        this.calcTotalPedido()
      }

      this.confimationCategories();
      this.scrollToCategory(categorieSelected, i)

      this.checkCategory(categorieSelected, i)
      this.changeDetectorRef.detectChanges()
    }

    //#endregion

    //#region Lógicas para calcular sabores e bordas de pizza

      //Lógica para calcular a divisão do valor do sabor
      logicDivision(){
        let quantyFlavor = 0;
        let valueFlavor = 0;

        let quantyEdge = 0;
        let valueEdge = 0;

        //Valores provisorios
        let provisionalValuePizza = 0;
        let provisionalValueEdge = 0;
        let provisionalValueProduct = 0;

        //Percorre todo o array temporario de adicionais
        this.additionalOrder.forEach(categoria => {
          categoria.additional.forEach(adicional =>{
            //Verifica se a categoria que o for esta passando é um sabor
            if(categoria.flavorCategory == 1)
            {
              quantyFlavor += adicional.quantitySub;
              valueFlavor += adicional.totalPriceSub
            }
            else if(categoria.edgeCategory == 1)
            {
              quantyEdge += adicional.quantitySub;
              valueEdge += adicional.totalPriceSub
            }
            else{
              provisionalValueProduct += adicional.totalPriceSub
            }
          })

        })

        //Se o value o quantidadeTotal forem diferentes de 0 ele atribui essa divisão ao valor provisorio
        if(valueFlavor && quantyFlavor)
        {
          provisionalValuePizza = valueFlavor / quantyFlavor;
        }
        if(valueEdge && quantyEdge)
        {
          provisionalValueEdge = valueEdge / quantyEdge;
        }

        this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

        this.valorBase = this.totalValue

        //Verifica se a variavel counter esta maior que zero para multiblicar o valor total pelo numero da varivel
        if(this.counter != 1){
          this.totalValue = this.totalValue * this.counter
        }

      }

      //Lógica para calcular a divisão do valor do sabor Média Ponderada
      logicDivisionCeil(){
        let quantyFlavor = 0;
        let valueFlavor = 0;

        let quantyEdge = 0;
        let valueEdge = 0;

        //Valores provisorios
        let provisionalValuePizza = 0;
        let provisionalValueEdge = 0;
        let provisionalValueProduct = 0;

        //Percorre todo o array temporario de adicionais
        this.additionalOrder.forEach(categoria => {
          categoria.additional.forEach(adicional =>{
            //Verifica se a categoria que o for esta passando é um sabor
            if(categoria.flavorCategory == 1)
            {
              quantyFlavor += adicional.quantitySub;
              valueFlavor += adicional.totalPriceSub
            }
            else if(categoria.edgeCategory == 1)
            {
              quantyEdge += adicional.quantitySub;
              valueEdge += adicional.totalPriceSub
            }
            else{
              provisionalValueProduct += adicional.totalPriceSub
              
            }
          })

        })

        //Se o value o quantidadeTotal forem diferentes de 0 ele atribui essa divisão ao valor provisorio
        if(valueFlavor && quantyFlavor)
        {
          provisionalValuePizza = valueFlavor / quantyFlavor;

          if(this.company.company.pizzaDivision == 2)
          {
            provisionalValuePizza = Math.ceil(provisionalValuePizza * 10) / 10;
          }
          else
          {
            provisionalValuePizza = Math.ceil(provisionalValuePizza * 2) / 2;
          }
        }
        if(valueEdge && quantyEdge)
        {
          provisionalValueEdge = valueEdge / quantyEdge;

          provisionalValueEdge = Number(provisionalValueEdge.toFixed(2));

          provisionalValueEdge = Math.floor(provisionalValueEdge * 10) / 10;
        }

        this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

        this.valorBase = this.totalValue

        //Verifica se a variavel counter esta maior que zero para multiblicar o valor total pelo numero da varivel
        if(this.counter != 1){
          this.totalValue = this.totalValue * this.counter
        }

      }

      //Lógica para calcular o maior valor do sabor Valor maior
      logicMaxValue(){

        //Valores provisorios
        let provisionalValuePizza = 0;
        let provisionalValueEdge = 0;
        let provisionalValueProduct = 0;

        //Percorre o Array de adicionais tempoararios
        this.additionalOrder.forEach(categoria => {
            categoria.additional.forEach(adicional =>{
              if(categoria.flavorCategory == 0 && categoria.edgeCategory == 0)
              {
                provisionalValueProduct += adicional.totalPriceSub
              }
              else if(categoria.edgeCategory == 1){
                //Calcula o valor do adicional dividido pela quantidade e se esse valor for maior do que esta registrado no valor provisorio, ele substitui
                if(adicional.quantitySub > 1){

                  const value = adicional.totalPriceSub / adicional.quantitySub;

                  if(value > provisionalValueEdge){

                    provisionalValueEdge = value;

                  }
                }
                else{
                  if(adicional.totalPriceSub > provisionalValueEdge)
                  {
                    provisionalValueEdge = adicional.totalPriceSub
                  }
                }
              }
              else if(categoria.flavorCategory == 1){
                //Calcula o valor do adicional dividido pela quantidade e se esse valor for maior do que esta registrado no valor provisorio, ele substitui
                if(adicional.quantitySub > 1)
                {
                  const value = adicional.totalPriceSub / adicional.quantitySub

                  if(value > provisionalValuePizza)
                  {
                    provisionalValuePizza = value
                  }
                }
                else{
                  if(adicional.totalPriceSub > provisionalValuePizza)
                  {
                    provisionalValuePizza = adicional.totalPriceSub
                  }
                }

              }

            })

            //Total value recebe o valor das variaveis acima
            this.totalValue = Number(provisionalValuePizza + provisionalValueEdge + provisionalValueProduct)

            this.valorBase = this.totalValue

            if(this.counter != 1){
              this.totalValue = this.totalValue * this.counter
            }
        })

      }

    //#endregion

    //#region Montar o pedido e passar para o carrinho

      //Responsavel por montar o objeto do pedido
      Pedido(){
        this.totalValue
        let quantityFlavor = 0;
        let quantityEdge = 0;
        let valueFlavor = 0;
        let valueEdge = 0;
        let pizzaPrice = 0
        let flavorsValue = 0;
        let baseFlavorsValue = 0;

        if(this.productOrder.pizza == 1){
          //Percorre a lista, Verifica se uma categoria é sabor e resgata o valor e as quantidades para Sabor e Borda
          this.additionalOrder.forEach(categoria => {
            if(categoria.flavorCategory == 1)
            {
              categoria.additional.forEach(adicional =>{
                quantityFlavor += adicional.quantitySub;
                valueFlavor += adicional.totalPriceSub
              })
              categoria.categoryLength = quantityFlavor
            }
            else if(categoria.edgeCategory == 1)
            {
              categoria.additional.forEach(adicional =>{
                quantityEdge += adicional.quantitySub;
                valueEdge += adicional.totalPriceSub
              })
              categoria.categoryLength = quantityEdge
            }
            
          })

          //Faz a divisão
          let divisionFlavor = valueFlavor / quantityFlavor
          let valueDivisionFlavor = divisionFlavor / quantityFlavor

          if(divisionFlavor && valueDivisionFlavor){
            if(this.company.company.pizzaDivision  == 2)
            {
              pizzaPrice = Math.ceil(divisionFlavor)
            }
            else{
              pizzaPrice = divisionFlavor
            }

          }


          let divisionEdge = valueEdge / quantityEdge
          let valueDivisionEdge = divisionEdge / quantityEdge

          //Percorre a lista novamente e adicona ao valores dos adicionais o valor dividido para Sabor e Borda
          this.additionalOrder.forEach(categoria => {
            if(categoria.flavorCategory == 1)
            {
              categoria.additional.forEach(adicional =>{
                if(this.company.company.pizzaDivision  == 2)
                {
                  adicional.totalPriceSub  = Number(valueDivisionFlavor)
                }
                else{
                  adicional.totalPriceSub  = Number(valueDivisionFlavor.toFixed(2))
                }
                
              })
            }
            else if(categoria.edgeCategory == 1){
              categoria.additional.forEach(adicional =>{
                if(this.company.company.pizzaDivision  == 2)
                {
                  adicional.totalPriceSub  = Number(valueDivisionEdge)
                }
                else{
                  adicional.totalPriceSub  = Number(valueDivisionEdge.toFixed(2))
                }

              })
            }

          })



          let additionalsValue = 0;
          this.additionalOrder.forEach(categoria => {

            if(categoria.flavorCategory == 0)
            {
              categoria.additional.forEach(additional =>{
                additionalsValue += additional.totalPriceSub
              })
            }
          })

          // flavor = flavor * this.counter;
          if(this.company.company.pizzaDivision  == 2){
            additionalsValue = Math.floor(additionalsValue * 10) / 10;
            additionalsValue = additionalsValue * this.counter
          }
          else{
            additionalsValue = additionalsValue * this.counter
          }

          

          if(additionalsValue != 0)
          {
            this.baseFlavorsValue = this.totalValue - additionalsValue;
            this.baseFlavorsValue = this.baseFlavorsValue / this.counter
            flavorsValue = this.totalValue - additionalsValue;
          }
          else{
            this.baseFlavorsValue = this.totalValue / this.counter
            flavorsValue = this.totalValue;
          }

        }
        //Envia as informações para a Classe
        this.pedido = new PedidoClass(this.productOrder, this.counter, this.textObservation, this.additionalOrder, this.valorBase, this.totalValue, pizzaPrice, flavorsValue, this.baseFlavorsValue)
      }

      //Adiciona o pedido e suas caracteristicas ao seu carrinho
      addProduct(){
        this.renderer.removeClass(this.document.body, 'body-no-scroll');
        this.Pedido()
        this.cartService.addToCart(this.pedido)
        this.sendEvent()
        this.toastr.success(`${this.produto.name} foi adicionado ao carrinho!`, 'Successo!', {progressBar: true, positionClass: 'toast-top-right', timeOut: 2000});
        this.cartService.productEditionSubject.next(this.temporaryOrde)
        // console.log(this.pedido)
      }

      //Se o usuario escolher continuar comprando ele removera a possibilidade de não fazer scroll, e adicionara o produto no carrinho
      continuarCompra(){
        // this.renderer.removeClass(this.document.body, 'body-no-scroll');
        if(this.statusCompany != 0 || this.typeParam.type == 1 || this.typeParam.type == 2)
        {
          if(this.permissionRequest){
            this.addProduct();
            this.goback()
          }
          else{
            this.toastr.error(`Não é possivel fazer pedido, aguarde o garçom!`, 'Pedido em conta!', { positionClass: 'toast-top-right', timeOut: 4000});
          }

        }
        else{
          this.toastr.error(`Não é possivel fazer pedido, pois a loja encontra-se fechada`, 'Loja Fechada!', { positionClass: 'toast-top-right', timeOut: 10000});
          this.goback()
        }


      }

      //Emite o evento de pedido gerado para o Pixel(Facebook)
      sendEvent(){
        this.pixelService.trackInitiateCheckout({
          action_source: 'website',
          event_id: Math.floor(Math.random() * 100000),
          event_time: Math.floor(Date.now() / 1000),
          user_data: {
            client_user_agent: navigator.userAgent,
          },
          eventref: this.pixelService.getEventRef() || '',
        });
      }

    //#endregion

    //#region Filtro
      //Função responsavel por fazer o filtro de pesquisa
      updatefilter(event: Event) {
        this.filter = (event?.target as HTMLInputElement).value.toLowerCase();
        this.counterLengthSub();

      }

      //Calcula se as subcategorias tem produtos visiveis
      counterLengthSub(){
        this.subCategoryEmpty = []
        this.produto.categories.forEach(category => {

          const visibleElementsCount = category.products.reduce((count, adicional) => {
            return adicional.name.toLowerCase().includes(this.filter) || this.filter == '' ? count + 1 : count;
          }, 0);
          
          this.subCategoryEmpty.push(visibleElementsCount);
        });

        // console.log(this.subCategoryEmpty)
      }
    //#endregion

  //#endregion

}
