import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from './Cache/cache.service';

@Injectable({
  providedIn: 'root'
})
export class PixelService {
  private pixelId: string | null = null;
  private fbclid: string = '';

  constructor(private route: ActivatedRoute, private cacheService: CacheService) {
    this.captureFbclid();
  }

  private captureFbclid() {
    this.route.queryParams.subscribe((params) => {
      this.fbclid = params['fbclid'] || '';
      if (this.fbclid) {
        this.cacheService.setItemSession('fbclid', this.fbclid);
      }
    });
  }

  getFbclid(): string {
    if(this.cacheService.getItemSession('fbclid')){
      return this.cacheService.getItemSession('fbclid')
    }
    else{
      return this.fbclid 
    }
  }

  initializePixel(pixelId: string): void {
    if (this.pixelId !== pixelId) {
      this.pixelId = pixelId;
      this.createScript(pixelId);
      this.createNoScript(pixelId);
    }
  }

  //Cria o script do Pixel
  private createScript(pixelId: string): void {
    const existingScript = document.querySelector<HTMLScriptElement>('[data-fb-pixel]');
    if (existingScript) {
      existingScript.remove();
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.dataset['fbPixel'] = 'true'; // Marca o script para facilitar identificação
    script.innerHTML = 
    `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');`;
    document.head.appendChild(script);
  }

  //Cria o NoScript do Pixel
  private createNoScript(pixelId: string): void {
    const existingNoscript = document.querySelector<HTMLDivElement>('[data-fb-noscript]');
    if (existingNoscript) {
      existingNoscript.remove();
    }
  
    const noscript = document.createElement('div');
    noscript.dataset['fbNoscript'] = 'true';
    noscript.innerHTML = `
      <noscript>
        <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
      </noscript>`;
    document.body.appendChild(noscript);
  }

  //Emite eventos customizados no Pixel(Facebook)
  trackCustom(eventName: string, data: any) {
    const fbclid = this.getFbclid();

    const eventData = {
      ...data,
      custom_data: {
        ...data.custom_data,
        fbclid: fbclid,
      },
    };


    if ((window as any).fbq) {
      (window as any).fbq('trackCustom', eventName, eventData);
    } else {
      console.error('Meta Pixel não está carregado!');
    }
  }

  //Emite evento de compra no Pixel(Facebook)
  trackPurchase(data: any) {
    const fbclid = this.getFbclid();
  
    const eventData = {
      ...data,
      fbclid: fbclid
    };
  
    if ((window as any).fbq) {
      (window as any).fbq('track', 'Purchase', eventData);
      console.warn('Event Purchase send success', eventData)
    } else {
      console.error('Meta Pixel não está carregado!');
    }
  }

  //Emite o evento de Adicionar ao carrinho no Pixel(Facebook)
  trackInitiateCheckout(data: any) {
    const fbclid = this.getFbclid();
  
    const eventData = {
      ...data,
      fbclid: fbclid
    };
  
    if ((window as any).fbq) {
      (window as any).fbq('track', 'InitiateCheckout', eventData);
      console.warn('Event InitiateCheckout send success', eventData)
    } else {
      console.error('Meta Pixel não está carregado!');
    }
  }

  //Emite o evento de Visualização de pagina no Pixel(Facebook)
  trackViewContent(data: any) {
    const fbclid = this.getFbclid();
  
    const eventData = {
      ...data,
      fbclid: fbclid
    };
  
    if ((window as any).fbq) {
      (window as any).fbq('track', 'ViewContent', eventData);
      console.warn('Event ViewContent send success', eventData);
    } else {
      console.error('Meta Pixel não está carregado!');
    }
  }

  getEventRef(): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('eventref') === 'fb_oea' ? 'fb_oea' : '';
  }

}
